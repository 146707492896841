var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('h3',[_vm._v("Produktdatenbank")]),_c('p',[_vm._v("In unserer Produktdatenbank finden Sie diverse Produkte von unserern Partnershops. Diese Produkte können Sie mit einem Klick auf \"+ Gearlist\" in Ihr persönliches Ausrüstungssortiment übernehmen. Sie sparen sich dadurch die Eingabe von Packmaßen, Produktbezeichnungen und Gewicht. Alle Produktinformationen können Sie natürlich überschreiben und individualisieren. ")]),_c('v-switch',{attrs:{"color":"primary","label":("Beim hinzufügen eines Produktes in die persönliche Ausrüstungsliste die Seite nicht verlassen: " + _vm.switchLabel)},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}}),_c('v-data-table',{staticClass:"elevation-1 color_table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.gear.items,"item-key":"id","loading":_vm.isLoading,"loading-text":"Wird geladen","group-by":"gearCategory.name","group-desc":"Kategorie","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","items-per-page":100,"footer-props":{
      itemsPerPageAllText: 'Alle',
      itemsPerPageText: 'Einträge pro Seite',
    }},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
    var group = ref.group;
    var headers = ref.headers;
    var toggle = ref.toggle;
    var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.pictureUrl",fn:function(ref){
    var item = ref.item;
return [_c('img',{attrs:{"src":item.pictureUrl,"height":"24","width":"auto"}})]}},{key:"item.volume",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLiter(item.volume)))])]}},{key:"item.aktionen",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.add(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-plus-square"})])]}}],null,true)},[_c('span',[_vm._v("In persönliche Ausrüstung übernehmen")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.buy(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-shopping-cart"})])]}}],null,true)},[_c('span',[_vm._v("Produkt kaufen")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true}])}),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{attrs:{"bottom":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.closeConfirmBar()}}},'v-btn',attrs,false),[_vm._v(" Abbrechen ")]),_c('v-btn',_vm._b({attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.deleteItem()}}},'v-btn',attrs,false),[_vm._v(" Bestätigen ")])]}}]),model:{value:(_vm.confirmBar),callback:function ($$v) {_vm.confirmBar=$$v},expression:"confirmBar"}},[_vm._v(" Möchten Sie den Ausrüstungsgegenstand wirklich löschen? ")])],1),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{attrs:{"bottom":"","timeout":3000},model:{value:(_vm.infoBar),callback:function ($$v) {_vm.infoBar=$$v},expression:"infoBar"}},[_vm._v(" Erfolgreich hinzugefügt ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }