<template>
  <div>
    <v-container>
      
    <h3>Produktdatenbank</h3>
    <p>In unserer Produktdatenbank finden Sie diverse Produkte von unserern Partnershops. Diese Produkte können Sie mit einem Klick auf "+ Gearlist" in Ihr persönliches Ausrüstungssortiment übernehmen. Sie sparen sich dadurch die Eingabe von Packmaßen, Produktbezeichnungen und Gewicht.
      Alle Produktinformationen können Sie natürlich überschreiben und individualisieren.
    </p>

    <v-switch
      v-model="switch1"
      color="primary"
      :label="`Beim hinzufügen eines Produktes in die persönliche Ausrüstungsliste die Seite nicht verlassen: ${switchLabel}`"
    ></v-switch>

    <v-data-table dense
      :headers="headers"
      :items="gear.items"
      item-key="id"
      :loading="isLoading"
      loading-text="Wird geladen"
      group-by="gearCategory.name"
      group-desc="Kategorie"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      :items-per-page="100"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >

    <template
                v-slot:group.header="{ group, headers, toggle, isOpen }"
              >
                <td :colspan="headers.length">
                  <v-btn @click="toggle" x-small icon :ref="group">
                    <v-icon v-if="isOpen">mdi-plus</v-icon>
                    <v-icon v-else>mdi-minus</v-icon>
                  </v-btn>
                  <span class="mx-5 font-weight-bold">{{ group }}</span>
                </td>
              </template>

      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.pictureUrl="{ item }">
        <img :src="item.pictureUrl" height="24" width="auto"/>
      </template>

      <template v-slot:item.volume="{ item }">
        <span>{{ toLiter(item.volume) }}</span>
      </template>

      <template v-slot:item.aktionen="{ item }">
         <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="add(item)"
              color="warning"
              dark
              v-bind="attrs"
              v-on="on"
            > <i class="far fa-plus-square"></i></v-btn>
          </template>
          <span>In persönliche Ausrüstung übernehmen</span>
        </v-tooltip>


         <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="buy(item)"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="ml-1"
            > <i class="fas fa-shopping-cart"></i></v-btn>
          </template>
          <span>Produkt kaufen</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <v-btn tile color="primary" @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie den Ausrüstungsgegenstand wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div class="text-center ma-2">
      <v-snackbar v-model="infoBar" bottom :timeout="3000">
        Erfolgreich hinzugefügt
      </v-snackbar>
    </div>

    </v-container>
  </div>
</template>

<script>
export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_gear_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      switch1: false,
      confirmBar: false,
      infoBar: false,
      selectedItem: null,
      headers: [
        { text: "Bild", value: "pictureUrl" },
        { text: "Hersteller", value: "manufacturer.name" },
        { text: "Kategorie", value: "gearCategory.name" },
        { text: "Name", value: "name" },
        { text: "Preis", value: "manufacturerPrice" },
        { text: "Gewicht in Gramm", value: "gramm" },
        { text: "Volumen (ml)", value: "volume" },
        { text: "Partnershop", value: "source" },
        { text: "Aktionen", value: "aktionen"}
      ],
      search: "",
    };
  },
  computed: {
    switchLabel() {
      return (this.switch1) ? "Ein": "Aus";
    },
    gear() {
      return this.$store.state.geartemplates.all;
    },
    isLoading() {
      return this.$store.state.geartemplates.all.loading;
    },
  },
  methods: {
    toLiter(volume) {
      return volume.toLocaleString();
    },
    async getData() {
      await this.$store.dispatch("geartemplates/getAll");
    },
    buy(item) {
      window.open(item.affiliateUrl);
    },
    add(item) {
      console.log(item);
      this.$store.dispatch("usergear/copyFrom", item).then(() => {
        //this.$root.$emit("reload_gear_data");

        if (!this.switch1) {
          this.$router.push("/gear");
        }
        else {
          this.infoBar = true;
        }
      });
    }
  },
};
</script>
