<template>
  <v-card style="padding: 1em">
    <v-card-title class="text-h5">
      Am häufigste verwendete Ausrüstung
    </v-card-title>
    <template v-if="isLoading">
      <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
    </template>
    <template v-else>
      <v-card-content v-if="mostUsed">
        <div v-for="gear in mostUsed" :key="gear.id">
            <h4 >{{ gear.name }}</h4>
            <v-progress-linear :color="gear.color" height="10" :value="gear.count"></v-progress-linear>
            <br/>
        </div>
    </v-card-content>
    <v-card-content v-else> 
        <span>Noch keine Ausrüstung erstellt.</span>
    </v-card-content>
    </template>

    
  </v-card>
</template>

<script>
export default {
  created() {
    this.reload();
  },
  computed: {
    mostUsed() {
      return this.$store.state.query.default.item;
    },
    isLoading() {
      return this.$store.state.query.default.isLoading;
    },
  },
  methods: {
    async reload() {
      await this.$store.dispatch("query/getMostUsedGear");
    },
  },
  filters: {
    todecimal: function (value) {
      return Number.parseFloat(value).toFixed(2);
    },
  },
};
</script>
