import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { packlists } from './packlists.module';
import { manufacturers } from './manufacturer.module';
import { gearcategories } from './gearcategories.module';
import { usergear } from './usergear.module';
import { geartemplates } from './geartemplates.module';
import { savedPacklists } from './savedPacklists.module';
import { query } from './query.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alert,
        packlists,
        manufacturers,
        gearcategories,
        usergear,
        geartemplates,
        savedPacklists,
        query
    }
});