import { queryService } from '../services';

export const query = {
    namespaced: true,
    state: {
        grammMetrics: {},
        default: {},
        selected: {}
    },
    actions: {

        getGrammMetrics({ commit }, id) {
            commit('getMetricsRequest');

            return new Promise((resolve, reject) => {
                queryService.getGrammMetrics(id)
                .then(
                    payload => {
                        commit('getMetricsSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getMetricsFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },

        getMostUsedGear({ commit }) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                queryService.getMostUsedGear()
                .then(
                    payload => {
                        commit('defaultSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('defaultFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },
        
        getNewestGear({ commit }) {
            commit('getRequest');

            return new Promise((resolve, reject) => {
                queryService.getNewestGear()
                .then(
                    payload => {
                        commit('getSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },

    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, payload) {
            state.selected = { item: payload };
        },
        getFailure(state, error) {
            state.selected = { error };
        },

        getMetricsRequest(state) {
            state.grammMetrics = { loading: true };
        },
        getMetricsSuccess(state, users) {
            state.grammMetrics = { items: users };
        },
        getMetricsFailure(state, error) {
            state.grammMetrics = { error };
        }
    }
}