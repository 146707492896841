<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h3>Hersteller-Verzeichnis</h3>
                    <span>Hier finden Sie eine Übersicht über das Herstellerverzeichnis unserer bekannten Outdoor-Marken.</span>
                    <span>Sollte Ihnen ein Hersteller fehlen, können Sie sich hier einen Datensatz erzeugen. Selbst hinzugefügte Hersteller sind nur für Sie sichtbar.</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ManufacturerOverview></ManufacturerOverview>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <NewManufacturer></NewManufacturer>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ManufacturerOverview from "../../tables/Manufacturer/ManufacturerTable.vue"
import NewManufacturer from "../../dialogs/Manufacturer/NewManufacturer.vue"


export default ({
    components: {
        ManufacturerOverview,
        NewManufacturer
    }
})
</script>
