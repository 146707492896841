import { geartemplatesService } from '../services';

export const geartemplates = {
    namespaced: true,
    state: {
        all: {},
        selected: {},
        default: {}
    },
    actions: {
        getAll({ commit }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                geartemplatesService.getAll(id)
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },
    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, user) {
            state.selected = { item: user };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}