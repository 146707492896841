<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h3>Ihre Ausrüstungsgegenstände</h3>
          <span
            >Hier können Sie Ihre Ausrüstung verwalten sowie "<router-link
              to="/lists"
              >Packlisten-Vorlagen</router-link
            >" erstellen und diese Packlisten dann bei Touren wiederverwenden
            und optimieren.</span
          >
          <span
            >Wir haben eine riesige Datenbank mit gängigen
            Ausrüstungsgegenstände welche auch bereits das Gewicht und die Maße
            des Gegenstandes behinhaltet. Sie können diese Informationen einfach
            übernehmen oder anpassen. Zum
            <router-link to="/cataloge">Katalog</router-link></span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <GearTable></GearTable>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NewGear></NewGear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GearTable from "../../tables/Gear/GearTable.vue";
import NewGear from "../../dialogs/Gear/NewGear.vue";

export default {
  components: {
    GearTable,
    NewGear,
  },
};
</script>
