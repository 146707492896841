<template>
  <div>
    <div>
      <v-data-table dense
        :headers="headers"
        :items="usergear.items"
        item-key="id"
        :loading="isLoading"
        loading-text="Wird geladen"
        :search="search"
        no-data-text="keine Daten vorhanden"
        no-results-text="keine Suchergebnisse"
        locale="de-de"
        class="elevation-1 color_table_secondary"
        :footer-props="{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Suchen"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.volume="{ item }">
          <span>{{ toLiter(item.volume) }}</span>
        </template>

<!--         <template v-slot:item.count="{ item }">
          <v-text-field v-model="item.count" label="Anzahl hinzufügen" outlined class="mt-2 mb-0 pb-0" dense
            placeholder="1"></v-text-field>
        </template> -->

        <template v-slot:item.mybuttons="{ item }">
          <v-btn tile color="primary" @click="Save(item)">Hinzufügen</v-btn>
        </template>

        <template v-slot:no-data>
          <v-btn tile color="primary" @click="getData"> Neu laden </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_usergear_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      PacklistId: this.packlistId,
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Hersteller", value: "manufacturer.name" },
        { text: "Name", value: "name" },
        { text: "Gewicht in Gramm", value: "gramm" },
        { text: "Volumen (ml)", value: "volume" },
        //{ text: "Anzahl", value: "count" },
        { text: "Aktionen", value: "mybuttons" },
      ],
      search: "",
    };
  },
  computed: {
    usergear() {
      return this.$store.state.usergear.all;
    },
    userId() {
      return this.$store.state.authentication.userId;
    },
    isLoading() {
      return this.$store.state.usergear.all.loading;
    },
  },
  props: {
    packlistId: null,
  },
  methods: {
    toLiter(volume) {
      return volume.toLocaleString();
    },
    async getData() {
      return this.$store.dispatch("usergear/getAll");
    },
    async Save(item) {
      if (this.PacklistId == null) return;

      /* if (item.count == null || item.count < 1) {
        alert("Bitte geben Sie eine Anzahl an, wie viele in die Packliste übernommen werden sollen.");
        return;
      } */

      let count = 1;
      /* try { count = parseInt(item.count); }
      catch {
        alert("Keine gültige Anzahl");
        return;
      } */

      let dto = {
        PacklistID: parseInt(this.PacklistId),
        UserGearId: parseInt(item.id),
        Count: parseInt(count),
      };

      console.log(dto);
      this.$store.dispatch("packlists/addItem", dto).then(() => {
        this.$root.$emit("reload_packlist_data");
        //window.location.reload();
      });
    },
  },
};
</script>
