<template>
  <v-card>
    <v-toolbar color="primary" dark>Ausrüstungsgegenstand anpassen</v-toolbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="Manufacturers"
                label="Hersteller"
                item-value="id"
                v-model="SelectedManufacturer"
                item-text="name"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="UserGear.Name"
                :rules="inputRules"
                :counter="25"
                label="Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="UserGear.ManufacturerPrice"
                label="Bezahlter Preis"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="UserGear.Gramm"
                label="Gewicht in Gramm"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5>Packmaß</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="UserGear.Width"
                label="Breite in cm"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="UserGear.Height"
                label="Höhe in cm"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="UserGear.Length"
                label="Länge in cm"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                disabled
                v-model="Volume"
                label="Volumen"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-divider inset></v-divider>
          </v-row>
          <v-row>
            <v-combobox
              v-model="selectedCategory"
              :items="items"
              item-value="id"
              item-text="name"
              label="Kategorie"
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.name.slice(0, 1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-combobox>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="Close()">Schließen</v-btn>
      <v-btn color="primary" tile @click="Save(dialog)">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  created() {
    this.GetManufacturers();
    this.GetGearCategories();
    this.GetUserGearTags()
  },
  props: {
    selectedGear: {},
  },
  data() {
    return {
      currentTags: [],
      currentManufaturer: null,
      selectedCategory: this.selectedGear.gearCategory,
      UserGear: {
        ID: this.selectedGear.id,
        Name: this.selectedGear.name,
        ManufacturerPrice: this.selectedGear.manufacturerPrice,
        ManufacturerId: this.selectedGear.manufacturerId,
        UserId: this.selectedGear.userId,
        Gramm: this.selectedGear.gramm,
        Width: this.selectedGear.width,
        Height: this.selectedGear.height,
        Length: this.selectedGear.length,
        GearCategory: this.selectedGear.gearCategory,
        GearCategoryId: this.selectedGear.gearCategoryId,
        AffiliateURL: this.selectedGear.affiliateURL,
        CreatedAt: this.selectedGear.createdAt,
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
      ],
    };
  },
  methods: {
    async GetManufacturers() {
      await this.$store.dispatch("manufacturers/getAll");
    },
    async GetGearCategories() {
      await this.$store.dispatch("gearcategories/getAll");
    },

    Reset() {
      (this.UserGear.ID = 0), (this.UserGear.Name = "");
      this.UserGear.ManufacturerPrice = 0.0;
      this.UserGear.Gramm = 0;
      this.UserGear.Width = 0;
      this.UserGear.Height = 0;
      this.UserGear.Length = 0;
      this.UserGear.IsContainer = false;
    },
    Close() {
      this.UserGear = null;
      this.$root.$emit("close_edit_usergear");
    },
    Save() {

      let manuID = 0;

      if (!parseInt(this.SelectedManufacturer)) {
        manuID =  parseInt(this.UserGear.ManufacturerId);
      }
      else {
        manuID = parseInt(this.SelectedManufacturer);
      }

      console.warn(this.selectedCategory.id);
      this.UserGear.GearCategoryId = this.selectedCategory.id;

      let dto = {
        ID: this.UserGear.ID,
        Name: this.UserGear.Name,
        ManufacturerId: parseInt(manuID),
        ManufacturerPrice: parseFloat(this.UserGear.ManufacturerPrice),
        GearCategoryId: parseInt(this.UserGear.GearCategoryId),
        Gramm: parseInt(this.UserGear.Gramm),
        Width: parseFloat(this.UserGear.Width),
        Height: parseFloat(this.UserGear.Height),
        Length: parseFloat(this.UserGear.Length),
        UserId: this.UserGear.UserId,
        AffiliateURL: this.AffiliateURL,
        CreatedAt: this.UserGear.CreatedAt
      };

      this.$store.dispatch("usergear/update", dto).then((response) => {
          console.log(response);
          this.$root.$emit("reload_usergear_data");
        this.Reset();
        this.$root.$emit("close_edit_usergear");
        });
    },
  },
  computed: {
    SelectedManufacturer: {
      get() {
        if (this.currentManufaturer == null) {
          if (this.Manufacturers != undefined) {
            this.Manufacturers.every((element) => {
              if (element.id == this.selectedGear.manufacturerId) {
                this.currentManufaturer = element;
                return false;
              }
              return true;
            });
          }
        }
        return this.currentManufaturer;
      },
      set(value) {
        this.currentManufaturer = value;
      },
    },
    items() {
      return this.$store.state.gearcategories.all.items;
    },
    selectedTags: {
      get() {
        let tags = [];
            if (this.UserGearTags != undefined) {
            this.UserGearTags.forEach((element) => {
              tags.push(element.gearCategory);
            });
          }
        return tags;
      },
      set(value) {
        this.currentTags = value;
      },
    },
    Manufacturers() {
      return this.$store.state.manufacturers.all.items;
    },
    Volume: {
      get: function() {
        return (
          parseFloat(this.UserGear.Height) *
          parseFloat(this.UserGear.Width) *
          parseFloat(this.UserGear.Length)
        );
      },
    },
    userId() {
      return this.$store.state.authentication.userId;
    },
  },
};
</script>
