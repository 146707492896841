<template>
  <v-card style="min-height: 6em">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5" v-if="isGearLoading">
            <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
        </v-list-item-title>
        <v-list-item-title class="text-h5" v-else>
          Gesamtanzahl von Gegenständen: {{ gearCount }}
        </v-list-item-title>
        <v-list-item-text v-if="gearLast.item != null || gearLast.item != undefined">
          <br />
          Zuletzt hinzugefügt: {{ gearLast.item.name }}
        </v-list-item-text>
        <v-list-item-text v-else>
          <br />
          Zuletzt hinzugefügt: -
        </v-list-item-text>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>

export default {
    created() {
        this.reload();
    },
    mounted() {
        this.$root.$on("reload_packlist_data", () => {
            this.reload();
        });
    },
    computed: {
        isGearLoading() {
            return this.$store.state.usergear.all.loading;
        },
        isLoading() {
            return this.$store.state.query.selected.loading;
        },
        gearLast() {
            return this.$store.state.query.selected;
        },
        gearCount() {
            if (this.$store.state.usergear.all.loading) {
                return 0;
            }
            if (this.$store.state.usergear.all.items) {
                return this.$store.state.usergear.all.items.length;
            }
            else {
                return 0;
            }
        },
    },
    methods: {
        async reload() {
            await this.$store.dispatch("usergear/getAll");
            await this.$store.dispatch("query/getNewestGear");
        },
    },
};
</script>
