<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-row>
        <h2>Packliste: {{ Name }}</h2>
        </v-row>
        <v-row>
        <p>{{ Description }}</p>
        </v-row>
        <v-row>
          <v-chip class="ma-2" text-color="white" color="primary">
            Gewicht: {{ totalWeight }} gr
          </v-chip>
          <v-chip class="ma-2" text-color="white" color="secondary">
            Gesamtvolumen: {{ totalVolume }} ml / {{ maxVolume }} ml ({{
              percent | todecimal
            }}%)
          </v-chip>
        </v-row>

        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-col>
      <v-col cols="6">
        <GChart
          type="PieChart"
          :data="grammMetrics"
          :options="tagChartGrammOptions"
        />
      </v-col>
    </v-row>

    <v-data-table
      dense
      :headers="headers"
      :items="gear"
      item-key="id"
      :loading="isLoading"
      :items-per-page="25"
      loading-text="Wird geladen"
      :search="search"
      group-by="GearCategory.Name"
      group-desc="Kategorie"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template
                v-slot:group.header="{ group, headers, toggle, isOpen }"
              >
                <td :colspan="headers.length">
                  <v-btn @click="toggle" x-small icon :ref="group">
                    <v-icon v-if="isOpen">mdi-plus</v-icon>
                    <v-icon v-else>mdi-minus</v-icon>
                  </v-btn>
                  <span class="mx-5 font-weight-bold">{{ group }}</span>
                </td>
              </template>

      <template v-slot:item.CreatedAt="{ item }">
        <span>{{ parseDate(item.CreatedAt) }}</span>
      </template>

      <template v-slot:item.Volume="{ item }">
        <span>{{ toLiter(item.Volume) }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="open(item)">
          fas fa-shopping-cart
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart,
  },
  data() {
    return {
      gear: [],
      chart: [],
      totalWeight: 0,
      maxVolume: 0,
      totalVolume: 0,
      tagChartGrammOptions: {
        chart: {
          title: "Aufteilung in Kategorien",
        },
        pieSliceText: "value",
      },
      headers: [
        {
          text: "Erstellt am",
          value: "CreatedAt",
        },
        {
          text: "Hersteller",
          value: "Manufacturer.Name",
        },
        {
          text: "Name",
          value: "Name",
        },
        {
          text: "Preis",
          value: "ManufacturerPrice",
        },
        {
          text: "Gewicht in Gramm",
          value: "Gramm",
        },
        {
          text: "Volumen (ml)",
          value: "Volume",
        },
        { text: "Kaufen", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      fetch(
        process.env.VUE_APP_API_ROUTE + "/api/SavedPacklists/" + this.accesscode
      )
        .then(this.handleResponse)
        .then((resp) => {
          console.log(resp);
          var as = JSON.parse(resp.jsonObject);
          this.gear = as;

          if (resp.name) {
            this.Name = resp.name;
          }

          if (resp.description) {
            this.description = resp.description;
          }

          if (resp.chartMetricGramm) {
            this.chart = JSON.parse(resp.chartMetricGramm);
          }

          if (resp.totalWeight) {
            this.totalWeight = resp.totalWeight;
          }

          if (resp.totalVolume) {
            this.totalVolume = resp.totalVolume;
          }

          if (resp.maxVolume) {
            this.maxVolume = resp.maxVolume;
          }
        });
    },
    open(item) {
      if (item && item.AffiliateUrl != null) {
        window.open(item.AffiliateUrl);
      } else {
        window.open(
          "https://www.amazon.de/s?k=" +
            item.Manufacturer.Name + " " + item.Name +
            "&i=sports&camp=1638&creative=6742&linkCode=ur2&linkId=9b085ad0f615a1bfc89f1e4175ecef53&tag=germanoutdoor-21"
        );
      }
    },
    json2array(json) {
      var result = [];
      var keys = Object.keys(json);
      keys.forEach(function(key) {
        result.push(json[key]);
      });
      return result;
    },
    handleResponse(response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          alert(error);
          return Promise.reject(error);
        }

        return data;
      });
    },
    toLiter(volume) {
      return volume.toLocaleString();
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
  },
  computed: {
    accesscode() {
      return this.$route.params.accesscode;
    },
    grammMetrics() {
      if (this.chart) {
        let data = [["Tag", "Gramm"]];

        console.log(this.chart);
        if (this.chart) {
          for (let i = 0; i < this.chart.length; i++) {
            data.push([this.chart[i].Tag, this.chart[i].Gramm]);
            console.log(data);
          }
        }
        return data;
      }
      return [];
    },
  },
  filters: {
    todecimal: function(value) {
      return Number.parseFloat(value).toFixed(2);
    },
  },
};
</script>
