<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="usergear.items"
      item-key="id"
      :loading="isLoading"
      :items-per-page="25"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      group-by="gearCategory.name"
      group-desc="Kategorie"

      locale="de-de"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-plus</v-icon>
            <v-icon v-else>mdi-minus</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ group }}</span>
        </td>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ parseDate(item.createdAt) }}</span>
      </template>

      <template v-slot:item.volume="{ item }">
        <span>{{ toLiter(item.volume) }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="showConfirmBar(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="editVisible"
    >
      <EditGear
        :selected-gear="selectedItem"
        v-if="selectedItem != null"
      ></EditGear>
    </v-dialog>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie den Ausrüstungsgegenstand wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import EditGear from "../../dialogs/Gear/EditGear.vue";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_usergear_data", () => {
      this.getData();
    });
    this.$root.$on("close_edit_usergear", () => {
      this.closeEdit();
    });
  },
  data() {
    return {
      currentItem: null,
      editVisible: false,
      confirmBar: false,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Hersteller", value: "manufacturer.name" },
        { text: "Kategorie", value: "gearCategory.name" },
        { text: "Name", value: "name" },
        { text: "Preis", value: "manufacturerPrice" },
        { text: "Gewicht in Gramm", value: "gramm" },
        { text: "Höhe (cm)", value: "height" },
        { text: "Breite (cm)", value: "width" },
        { text: "Länge (cm)", value: "length" },
        { text: "Volumen (ml)", value: "volume" },
        { text: "Packliste", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  components: {
    EditGear,
  },
  computed: {
    selectedItem: {
      get() {
        return this.currentItem;
      },
      set(value) {
        this.currentItem = value;
      },
    },
    usergear() {
      return this.$store.state.usergear.all;
    },
    userId() {
      return this.$store.state.authentication.userId;
    },
    isLoading() {
      return this.$store.state.usergear.all.loading;
    },
  },
  methods: {
    toLiter(volume) {
      return volume.toLocaleString();
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("usergear/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store.dispatch("usergear/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_usergear_data");
        this.closeConfirmBar();
      });
    },
    editItem(item) {
      this.selectedItem = item;
      console.log(this.selectedItem);
      if (this.selectedItem == null) return;
      this.editVisible = true;
    },
    closeEdit() {
      this.editVisible = false;
      this.selectedItem = null;
    },
  },
};
</script>
