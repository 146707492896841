import * as Sentry from "@sentry/vue";

import {
    authHeader
} from '../helpers';

export const gearcategoriesService = {
    getAll,
    createNew,
    updateExisting,
    deleteExisting
};


const route = '/api/GearCategories'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function createNew(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function updateExisting(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.ID, requestOptions).then(handleResponse);
}


function deleteExisting(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('vue-token');
                location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            //this.$store.dispatch("alert/error", error);
            Sentry.captureException(error);
            return Promise.reject(error);
        }

        return data;
    });
}