import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from "../components/views/Dashboard"
import MyPacklists from "../components/views/packlists/MyPacklists"
import MyGear from "../components/views/gear/MyGear"
import GearCataloge from "../components/views/gear/GearCataloge"

import MyManufacturers from "../components/views/manufacturers/MyManufacturers"
import PacklistDetails from "../components/views/packlists/PacklistDetails.vue"; 
import SharePacklist from "../components/views/sharepacklist/default.vue";
import MyShares from "../components/views/packlists/MyShares.vue";


Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Dashboard,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/cataloge',
    name: 'Cataloge',
    component: GearCataloge,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/packlists',
    name: 'Packlists',
    component: MyPacklists,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/share/:accesscode',
    name: 'Share',
    component: SharePacklist,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/shares',
    name: 'MyShares',
    component: MyShares,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/gear',
    name: 'Gear',
    component: MyGear,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/packlist/:id',
    name: 'PacklistDetails',
    component: PacklistDetails,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/manufacturers',
    name: 'Manufacturers',
    component: MyManufacturers,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      isAuthenticated: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
    } else if (Vue.$keycloak.hasResourceRole(process.env.VUE_APP_KEYCLOAK_ROLE)) {
      // The user was authenticated, and has the app role
      Vue.$keycloak.updateToken(70)
        .then(() => {
          localStorage.setItem("vue-refresh-token",  Vue.$keycloak.refreshToken);
          next()
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      localStorage.setItem("vue-token",  Vue.$keycloak.token);
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page
      next()
    }
  } else {
    localStorage.setItem("vue-token",  Vue.$keycloak.token);
    // This page did not require authentication
    next()
  }
})

export default router
