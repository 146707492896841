<template>
  <v-row>
    <v-col>
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn tile color="primary" v-bind="attrs" v-on="on">Neue Packliste</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Neue Packkliste hinzufügen</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="Packlist.PacklistName"
                        :rules="inputRules"
                        :counter="25"
                        label="Name Ihrer Packliste"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="Packlist.Description"
                        :rules="inputRules"
                        :counter="100"
                        label="Beschreibung"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <div class="d-flex">
                      <v-checkbox
                        v-model="Packlist.UseLimits"
                        label="Schwellenwerte verwenden"
                      ></v-checkbox>
                    </div>
                  </v-row>

                  <v-expansion-panels
                    v-model="panel"
                    :disabled="!Packlist.UseLimits"
                    multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Gewicht Schwellenwerte (in Gramm)</v-expansion-panel-header>
                      <v-expansion-panel-content>
                         <v-row>
                    <blockquote>Hier können Sie Schwellenwerte für Ihre Packliste eingeben. Wenn die Grenzwerte vom Gesamtgewicht der Gegenstände in Ihrer Packliste überschritten sind werden die Werte demensprechend farblich hervorgehoben.</blockquote>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.LimitLow"
                        label="Niedriger (Grün)"
                        placeholder="5000"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.LimitMedium"
                        label="Mittel (Geld)"
                        placeholder="9000"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.LimitHigh"
                        label="Hoch (Orange)"
                        placeholder="15000"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.LimitVeryHigh"
                        label="Sehr hoch (Rot)"
                        placeholder="20000"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>Packmaß Schwellenwerte (in Prozent)</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                    <blockquote>Hier können Sie Schwellenwerte für Ihre Packliste eingeben. Wenn die Grenzwerte für Ihr verfügbares Volumen im Rucksack überschritten wird gibt es entsprechende Warnungen.</blockquote>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.PercentLow"
                        label="Niedriger (Grün)"
                        placeholder="30"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.PercentMedium"
                        label="Mittel (Gelb)"
                        placeholder="50"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.PercentHigh"
                        label="Hoch (Orange)"
                        placeholder="80"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Packlist.PercentVeryHigh"
                        label="Sehr hoch (Rot)"
                        placeholder="100"
                        :disabled="!Packlist.UseLimits"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Schließen</v-btn>
              <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      Packlist: {
        PacklistName: "",
        Description: "",
        UseLimits: false,
        PercentLow: 30,
        PercentMedium: 50,
        PercentHigh: 80,
        PercentVeryHigh: 100,
        LimitLow: 5000,
        LimitMedium: 9000,
        LimitHigh: 15000,
        LimitVeryHigh: 20000
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
      ],
    };
  },
  methods: {
    Reset() {
      this.Packlist.PacklistName = "";
      this.Packlist.Description = "";
    },
    Save(dialog) {
      let dto = {
        PacklistName: this.Packlist.PacklistName,
        Description: this.Packlist.Description,
        PercentLow: parseInt(this.Packlist.PercentLow),
        PercentMedium: parseInt(this.Packlist.PercentMedium),
        PercentHigh: parseInt(this.Packlist.PercentHigh),
        PercentVeryHigh:parseInt( this.Packlist.PercentVeryHigh),
        LimitLow: parseInt(this.Packlist.LimitLow),
        LimitMedium: parseInt(this.Packlist.LimitMedium),
        LimitHigh: parseInt(this.Packlist.LimitHigh),
        LimitVeryHigh: parseInt(this.Packlist.LimitVeryHigh),
        UseLimits: this.Packlist.UseLimits
      };
      console.log(dto);
      this.$store.dispatch("packlists/create", dto).then(() => {
        this.$root.$emit("reload_packlist_data");
        dialog.value = false;
        this.Reset();
      });
    },
  },
};
</script>
