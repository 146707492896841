var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"mt-5 pb-0 pt-2",attrs:{"cols":"2"}},[_c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","fab":"","small":"","dark":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-grid ")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Apps")])]),_c('v-card-text',[_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.openAccount()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('span',[_vm._v("Mein Account")])]),_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.open('https://community.german-outdoors.de')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}])},[_c('span',[_vm._v("Community")])]),_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.open('https://packlight.german-outdoors.de')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bag-personal-outline")])],1)]}}])},[_c('span',[_vm._v("Packlisten")])]),_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.open('https://buckets.german-outdoors.de')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1)]}}])},[_c('span',[_vm._v("Bucket Listen")])]),_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.open('https://pricemonitor.german-outdoors.de')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alarm-light")])],1)]}}])},[_c('span',[_vm._v("Preis Alarm")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }