<template>
    <v-col cols="2" class="mt-5 pb-0 pt-2">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" fab small dark>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-dots-grid </v-icon>
          </v-btn>
        </template>
        <v-card elevation="2" outlined class="mx-auto my-12">
          <v-card-title>
            <span class="headline">Apps</span>
          </v-card-title>
          <v-card-text>
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="openAccount()"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Mein Account</span>
            </v-tooltip>
  
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://community.german-outdoors.de')"
                >
                  <v-icon>mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span>Community</span>
            </v-tooltip>
  
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://packlight.german-outdoors.de')"
                >
                  <v-icon>mdi-bag-personal-outline</v-icon>
                </v-btn>
              </template>
              <span>Packlisten</span>
            </v-tooltip>
  
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://buckets.german-outdoors.de')"
                >
                  <v-icon>mdi-format-list-checks</v-icon>
                </v-btn>
              </template>
              <span>Bucket Listen</span>
            </v-tooltip>
  
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://pricemonitor.german-outdoors.de')"
                >
                  <v-icon>mdi-alarm-light</v-icon>
                </v-btn>
              </template>
              <span>Preis Alarm</span>
            </v-tooltip>
  
            <!--<v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://tours.german-outdoors.de')"
                >
                  <v-icon>mdi-compass-outline</v-icon>
                </v-btn>
              </template>
              <span>Tourenplanung</span>
            </v-tooltip>
  
            <v-tooltip top style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="open('https://friends.german-outdoors.de')"
                >
                  <v-icon>mdi-hiking</v-icon>
                </v-btn>
              </template>
              <span>Outdoor Buddy</span>
            </v-tooltip>-->
          </v-card-text>
        </v-card>
      </v-speed-dial>
    </v-col>
  </template>
  
  <script>
  import { getUsername, getInitials } from "../../helpers";
  
  export default {
    //Data
    data() {
      return {
        transition: "slide-y-reverse-transition",
        direction: "top",
        fab: false,
        fling: false,
        hover: false,
        tabs: true,
        top: false,
        right: true,
        bottom: true,
        left: false,
      };
    },
  
    //Logic
    computed: {
      username() {
        return getUsername();
      },
      initials() {
        return getInitials();
      },
      getUser() {
        let msg = this.$store.state.authentication.user;
        return msg;
      },
    },
    methods: {
      getUserInfoURL() {
        return process.env.VUE_APP_KEYCLOAK_USERACCOUNT_ROUTE;
      },
      open(url) {
        window.open(url, "_blank");
      },
      openAccount() {
        window.open(this.getUserInfoURL(), "_blank");
      },
      clear() {
        this.$store.dispatch("alert/clear");
      },
    },
  };
  </script>
  
  
  