var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 color_table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.usergear.items,"item-key":"id","loading":_vm.isLoading,"items-per-page":25,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","group-by":"gearCategory.name","group-desc":"Kategorie","locale":"de-de","footer-props":{
      itemsPerPageAllText: 'Alle',
      itemsPerPageText: 'Einträge pro Seite',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"group.header",fn:function(ref){
    var group = ref.group;
    var headers = ref.headers;
    var toggle = ref.toggle;
    var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDate(item.createdAt)))])]}},{key:"item.volume",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLiter(item.volume)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showConfirmBar(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true}])}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.editVisible),callback:function ($$v) {_vm.editVisible=$$v},expression:"editVisible"}},[(_vm.selectedItem != null)?_c('EditGear',{attrs:{"selected-gear":_vm.selectedItem}}):_vm._e()],1),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{attrs:{"bottom":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.closeConfirmBar()}}},'v-btn',attrs,false),[_vm._v(" Abbrechen ")]),_c('v-btn',_vm._b({attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.deleteItem()}}},'v-btn',attrs,false),[_vm._v(" Bestätigen ")])]}}]),model:{value:(_vm.confirmBar),callback:function ($$v) {_vm.confirmBar=$$v},expression:"confirmBar"}},[_vm._v(" Möchten Sie den Ausrüstungsgegenstand wirklich löschen? ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }