<template>
  <v-container>
    <v-row>
      <v-col cols="12">
          <h3>Ihre geteilten Inhalte</h3>
          <span>Hier finden Sie sämtliche Packlisten welche Sie geteilt (oder in Ihrer Website eingebunden) haben.</span>
          <v-alert
      dense
      border="left"
      type="warning"
    >
      Achtung: Das löschen von geteilten Inhalten führt dazu, das der Inhalt nicht mehr für andere Zugreifbar ist. Sollten Sie einen der Inhalte auf Ihrer Website eingebunden haben wird er auch dort gelöscht.
    </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="shares.items"
          item-key="id"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          locale="de-de"
          class="elevation-1 color_table"
          :footer-props="{
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Einträge pro Seite',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Suchen"
              class="mx-4"
            ></v-text-field>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>{{ parseDate(item.createdAt) }}</span>
          </template>

          <template v-slot:item.x="{ item }">
            <span>{{ parseAccessCode(item) }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="showItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="showConfirmBar(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn tile color="primary" @click="getData"> Neu laden </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie die Freigabe wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<style scoped>
.v-data-table {
  width: 100% !important;
}
</style>

<script>
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_savedPacklists_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Zugangs Code", value: "accessCode" },
        { text: "Name", value: "name" },
        { text: "Beschreibung", value: "description" },
        { text: "URL", value: "x" },
        { text: "Packliste", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    shares() {
      return this.$store.state.savedPacklists.all;
    },
    userId() {
      return this.$store.state.authentication.userId;
    },
    isLoading() {
      return this.$store.state.savedPacklists.all.loading;
    },
  },
  methods: {
    parseAccessCode(item) {
      return "https://packlight.german-outdoors.de/#share/" + item.accessCode;
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("savedPacklists/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store
        .dispatch("savedPacklists/delete", this.selectedItem)
        .then(() => {
          this.$root.$emit("reload_savedPacklists_data");
          this.closeConfirmBar();
        });
    },
    showItem(item) {
      console.log(item);
      this.$router.push("/share/" + item.accessCode);
    },
  },
};
</script>
