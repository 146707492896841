var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('h2',[_vm._v("Packliste: "+_vm._s(_vm.Name))])]),_c('v-row',[_c('p',[_vm._v(_vm._s(_vm.Description))])]),_c('v-row',[_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white","color":"primary"}},[_vm._v(" Gewicht: "+_vm._s(_vm.totalWeight)+" gr ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white","color":"secondary"}},[_vm._v(" Gesamtvolumen: "+_vm._s(_vm.totalVolume)+" ml / "+_vm._s(_vm.maxVolume)+" ml ("+_vm._s(_vm._f("todecimal")(_vm.percent))+"%) ")])],1),_c('v-row',[_c('v-divider')],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.grammMetrics,"options":_vm.tagChartGrammOptions}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 color_table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.gear,"item-key":"id","loading":_vm.isLoading,"items-per-page":25,"loading-text":"Wird geladen","search":_vm.search,"group-by":"GearCategory.Name","group-desc":"Kategorie","no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
      itemsPerPageAllText: 'Alle',
      itemsPerPageText: 'Einträge pro Seite',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"group.header",fn:function(ref){
    var group = ref.group;
    var headers = ref.headers;
    var toggle = ref.toggle;
    var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.CreatedAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDate(item.CreatedAt)))])]}},{key:"item.Volume",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLiter(item.Volume)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.open(item)}}},[_vm._v(" fas fa-shopping-cart ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }