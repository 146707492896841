<template>
  <v-row>
    <v-col>
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn tile color="primary" v-bind="attrs" v-on="on"
            >Neuer Ausrüstungsgegenstand</v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Neuen Ausrüstungsgegenstand hinzufügen</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        :items="Manufacturers"
                        label="Hersteller"
                        item-value="id"
                        v-model="SelectedManufacturer"
                        item-text="name"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="UserGear.Name"
                        :rules="inputRules"
                        :counter="25"
                        label="Name"
                        required
                      ></v-text-field>
                    </v-col>

                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="UserGear.ManufacturerPrice"
                        label="Bezahlter Preis"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="UserGear.Gramm"
                        label="Gewicht in Gramm"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h5>Packmaß</h5>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="UserGear.Width"
                        label="Breite in cm"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="UserGear.Height"
                        label="Höhe in cm"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="UserGear.Length"
                        label="Länge in cm"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        disabled
                        v-model="Volume"
                        label="Volumen"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                  <v-divider inset></v-divider>
                  </v-row>

                  <v-row>
                     <v-combobox
                        v-model="selectedCategory"
                        :items="items"
                        item-value="id"
                        item-text="name"
                        label="Kategorie auswählen"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            <v-avatar
                              class="accent white--text"
                              left
                              v-text="data.item.name.slice(0, 1).toUpperCase()"
                            ></v-avatar>
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-combobox>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Schließen</v-btn>
              <v-btn color="primary" tile @click="Save(dialog)">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  created() {
    this.GetManufacturers();
    this.GetGearCategories();
  },
  data() {
    return {
      selectedCategory: null,
      SelectedManufacturer: null,
      dialog: false,
      UserGear: {
        Name: "",
        ManufacturerPrice: 0.0,
        Gramm: 0,
        Width: 0,
        Height: 0,
        Length: 0,
        IsContainer: false
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
      ],
    };
  },
  methods: {
    async GetManufacturers() {
      await this.$store.dispatch("manufacturers/getAll");
    },
     async GetGearCategories() {
      await this.$store.dispatch("gearcategories/getAll");
    },
    Reset() {
        this.UserGear.Name = "";
        this.UserGear.ManufacturerPrice = 0.0;
        this.UserGear.ManufacturerId = null;
        this.UserGear.Gramm = 0;
        this.UserGear.Width = 0;
        this.UserGear.Height = 0;
        this.UserGear.Length = 0;
        this.UserGear.GearCategoryId = null;
    },
    Save(dialog) {
      console.warn(this.selectedCategory.id);
      this.UserGear.GearCategoryId = this.selectedCategory.id;

      let dto = {
        Name: this.UserGear.Name,
        ManufacturerId: parseInt(this.SelectedManufacturer),
        ManufacturerPrice: parseFloat(this.UserGear.ManufacturerPrice),
        Gramm: parseInt(this.UserGear.Gramm),
        Width: parseFloat(this.UserGear.Width),
        Height: parseFloat(this.UserGear.Height),
        Length: parseFloat(this.UserGear.Length),
        GearCategoryId: parseInt(this.UserGear.GearCategoryId)
      };

      this.$store.dispatch("usergear/create", dto).then((response) => {
        console.log(response);
        this.$root.$emit("reload_usergear_data");
        dialog.value = false;
        this.Reset();
      });
    },
  },
  computed: {
    items() {
       return this.$store.state.gearcategories.all.items;
    },
    Manufacturers() {
      return this.$store.state.manufacturers.all.items;
    },
    Volume: {
      get: function() {
        return (
          parseFloat(this.UserGear.Height) * parseFloat(this.UserGear.Width) * parseFloat(this.UserGear.Length)
        );
      },
    },
  },
};
</script>
