<template>
  <div>
    <v-data-table dense
      :headers="headers"
      :items="manufacturers.items"
      item-key="id"
      :loading="isLoading"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      :items-per-page="25"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" v-if="deletable(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="showConfirmBar(item)" v-if="deletable(item)">
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

      <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="editVisible">
      <EditManufacturer v-if="editVisible" :manufacturer="selectedItem"></EditManufacturer>
      </v-dialog>
   


    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie den Hersteller wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import EditManufacturer from '../../dialogs/Manufacturer/EditManufacturer.vue';
export default {
    created() {
        this.getData();
    },
    mounted() {
        this.$root.$on("reload_manufacturer_data", () => {
            this.getData();
        });
        this.$root.$on("close_edit_manufacturer", () => {
          this.editVisible = false;
          this.selectedItem = null;
            this.getData();
        });       
    },
    data() {
        return {
            confirmBar: false,
            selectedItem: null,
            editVisible: false,
            headers: [
                { text: "Hersteller", value: "name" },
                { text: "Beschreibung", value: "description" },
                { text: "Hersteller Webseite", value: "website" },
                { text: "Aktionen", value: "actions", sortable: false },
            ],
            search: "",
        };
    },
    computed: {
        manufacturers() {
            return this.$store.state.manufacturers.all;
        },
        isLoading() {
            return this.$store.state.manufacturers.all.loading;
        },
    },
    methods: {
        async getData() {
            await this.$store.dispatch("manufacturers/getAll");
        },
        closeConfirmBar() {
            this.confirmBar = false;
            this.selectedItem = null;
        },
        showConfirmBar(item) {
            this.selectedItem = item;
            this.confirmBar = true;
        },
        deletable(item) {
            return (item.userId != null);
        },
        editItem(item) {
          this.selectedItem = item;
          console.log(this.selectedItem);
          if (this.selectedItem == null) return;
          this.editVisible = true;
        },
        deleteItem() {
            if (this.selectedItem == null)
                return;
            this.$store.dispatch("manufacturers/delete", this.selectedItem).then(() => {
                this.$root.$emit("reload_manufacturer_data");
                this.closeConfirmBar();
            });
        },
    },
    components: { EditManufacturer }
};
</script>
