<template>
  <v-container>
    <v-row v-if="packlists.items" >
      <v-col col="3"  v-for="item in packlists.items" :key="item.id">
        <v-card
          class="mx-auto my-12"
          max-width="374"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          ></v-img>

          <v-card-title>{{ item.packlistName }}</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0"> </v-row>

            <div class="my-4 text-subtitle-1">
              {{ item.description }}
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Metrik</v-card-title>

          <v-card-text>
            <v-chip-group
              active-class="deep-purple accent-4 white--text"
              column
            >
              <v-chip>8,4 kg</v-chip>

              <v-chip>70 %</v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-actions>
            <v-btn color="deep-purple lighten-2" text @click="editItem(item)">
              Öffnen
            </v-btn>

            <v-btn color="red" text @click="showConfirmBar(item)">
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie die Packliste wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_packlist_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Name", value: "packlistName" },
        { text: "Beschreibung", value: "description" },
        { text: "Packliste", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    packlists() {
      return this.$store.state.packlists.all;
    },
    userId() {
      return this.$store.state.authentication.userId;
    },
    isLoading() {
      return this.$store.state.packlists.all.loading;
    },
  },
  methods: {
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("packlists/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store.dispatch("packlists/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_packlist_data");
        this.closeConfirmBar();
      });
    },
    editItem(item) {
      this.$router.push("/packlist/" + item.id);
    },
  },
};
</script>
