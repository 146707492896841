<template>
  <div>
    <v-container fluid style="margin: 0px; padding: 0px">
      <v-parallax
        src="https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
        height="380"
      ></v-parallax>
    </v-container>
    <v-container style="margin-top: -24em">
      <v-card>
        <v-row style="margin-top: 1em">
          <v-col md="8" sm="12">
            <v-card-title>
              Packliste: {{ Packlist.packlistName }}
            </v-card-title>
            <v-card-text>
              {{ Packlist.description }}
            </v-card-text>
            <v-chip class="ma-2" :color="limitColor(Gramm)" text-color="white" v-if="Packlist.useLimits">
              Gewicht: {{ Gramm }} gr
            </v-chip>
            <v-chip v-if="Packlist.useLimits"
              class="ma-2"
              :color="limitLiterColor(Liter)"
              text-color="white"
            >
              Gesamtvolumen: {{ Liter }} ml / {{ AvailableLiter }} ml ({{
                percent | todecimal
              }}%)
            </v-chip>
          </v-col>

          <v-col md="12" sm="12" lg="4">
            <GChart
              type="PieChart"
              :data="grammMetrics"
              :options="tagChartGrammOptions"
            />
          </v-col>
        </v-row>

        <v-card-actions>
          <v-card-action>
            <v-btn color="info" @click="NewShareCopy()">
              <i class="fas fa-share-alt pr-1" />
            </v-btn>

            <v-alert
              class="mt-2"
              dense
              type="info"
              outlined
              elevation="2"
              v-if="AccessCode"
              >Ihr findet Eure Teilen Links & Codes unter dem Menüpunkt "Meine
              geteilten Inhalte"</v-alert
            >
          </v-card-action>
        </v-card-actions>
      </v-card>

      <v-row style="margin-top: 1em">
        <v-col md="5" sm="12">
          <v-card>
            <v-card-title> Ihre Ausrüstung </v-card-title>

            <v-card-text>
              Wählen Sie hier die Gegenstände aus die Sie in Ihre Packliste
              packen möchten
            </v-card-text>
            <GearTableShort :packlist-id="id"></GearTableShort>
            <br />
          </v-card>

          <NewGear style="margin-top: 2em;"></NewGear>
        </v-col>

        <v-col cols="1" style="margin-top: 80px" class="d-none d-md-flex">
          <v-icon style="font-size: 72px">mdi-arrow-right-bold-outline</v-icon>
        </v-col>

        <v-col md="6" sm="12">
          <v-card>
            <v-card-title>
              <v-icon x-large color="#59815b">mdi-bag-personal</v-icon>
              Rucksäcke & Taschen?
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="Packlist.backpacks"
              item-key="id"
              :loading="isLoading"
              loading-text="Wird geladen"
              :search="search"
              no-data-text="keine Daten vorhanden"
              no-results-text="keine Suchergebnisse"
              locale="de-de"
              class="elevation-1 color_table"
              :footer-props="{
                itemsPerPageAllText: 'Alle',
                itemsPerPageText: 'Einträge pro Seite',
              }"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Suchen"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:item.createdAt="{ item }">
                <span>{{ parseDate(item.createdAt) }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="showConfirmBar(item)">
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:item.totalGramm="{ item }">
                <span>{{ total(item.count, item.userGear.gramm) }}</span>
              </template>
              <template v-slot:item.totalVolume="{ item }">
                <span>{{ total(item.count, item.userGear.volume) }}</span>
              </template>

              <template v-slot:no-data>
                <blockquote>
                  Noch keine Rucksäcke oder Taschen hinzugefügt. Volumen kann
                  erst berechnet werden wenn mindestens ein Gegenstand welcher
                  als Rucksack oder Tasche deklariert ist hinzugefügt wurde.
                </blockquote>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mt-5">
            <v-card-title>
              <v-icon x-large color="#59815b">mdi-hiking</v-icon> Eingepackte
              Gegenstände
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="Packlist.packlistGear"
              item-key="id"
              group-by="userGear.gearCategory.name"
              group-desc="Kategorie"
              :loading="isLoading"
              loading-text="Wird geladen"
              :search="search"
              no-data-text="keine Daten vorhanden"
              no-results-text="keine Suchergebnisse"
              locale="de-de"
              class="elevation-1 color_table"
              :footer-props="{
                itemsPerPageAllText: 'Alle',
                itemsPerPageText: 'Einträge pro Seite',
              }"
            >
              <template
                v-slot:group.header="{ group, headers, toggle, isOpen }"
              >
                <td :colspan="headers.length">
                  <v-btn @click="toggle" x-small icon :ref="group">
                    <v-icon v-if="isOpen">mdi-plus</v-icon>
                    <v-icon v-else>mdi-minus</v-icon>
                  </v-btn>
                  <span class="mx-5 font-weight-bold">{{ group }}</span>
                </td>
              </template>

              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Suchen"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:item.createdAt="{ item }">
                <span>{{ parseDate(item.createdAt) }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small @click="showConfirmBar(item)">
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:item.totalGramm="{ item }">
                <span>{{ total(item.count, item.userGear.gramm) }}</span>
              </template>
              <template v-slot:item.totalVolume="{ item }">
                <span>{{ total(item.count, item.userGear.volume) }}</span>
              </template>

              <template v-slot:item.count="{ item }">
                <v-text-field
                  v-model="item.count"
                  type="number"
                  label="Anzahl"
                  @change="countChange(item)"
                ></v-text-field>
              </template>

              <template v-slot:no-data>
                <v-btn tile color="primary" @click="reload"> Neu laden </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <div class="text-center ma-2">
        <v-snackbar v-model="confirmBar" bottom :timeout="10000">
          Möchten Sie den Eintrag wirklich löschen?
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
              Abbrechen
            </v-btn>

            <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
              Bestätigen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { GChart } from "vue-google-charts";

import GearTableShort from "../../tables/Gear/GearTableShort.vue";
//import NewPacklistGear from "../packlists/NewPacklistGear.vue"
import NewGear from "../../dialogs/Gear/NewGear.vue";

export default {
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_packlist_details", () => {
      this.reload();
    });
    this.$root.$on("reload_packlist_data", () => {
      this.reload();
    });
  },
  data() {
    return {
      percent: 0,
      AccessCode: "",
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Anzahl", value: "count" },
        { text: "Hersteller", value: "userGear.manufacturer.name" },
        { text: "Name", value: "userGear.name" },
        { text: "Kategorie", value: "userGear.gearCategory.name" },
        { text: "Gesamtgewicht", value: "totalGramm" },
        { text: "Gesamtvolumen", value: "totalVolume" },
        { text: "Packliste", value: "actions", sortable: false },
      ],
      search: "",

      tagChartGrammOptions: {
        chart: {
          title: "Aufteilung in Kategorien",
        },
        pieSliceText: "value",
      },
    };
  },

  components: {
    GChart,
    NewGear,
    GearTableShort,
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    grammMetrics() {
      let json = this.$store.state.query.grammMetrics;
      let data = [["Tag", "Gramm"]];
      if (json && json.items) {
        for (let i = 0; i < json.items.length; i++) {
          data.push([json.items[i].tag, json.items[i].gramm]);
        }
      }

      return data;
    },
    packlist() {
      return this.$store.state.packlists.selected;
    },
    Packlist() {
      let item = { packlistName: "", description: "", createdAt: null, useLimits: false };
      if (this.packlist.item != undefined) {
        item = this.packlist.item;
      }
      return item;
    },
    Limits() {
      let values = {
        low: 5000,
        medium: 9000,
        high: 15000,
        veryhigh: 20000,
      };

      if (this.Packlist != null) {
        console.log(this.Packlist);
        values.low = this.Packlist.limitLow
          ? this.Packlist.limitLow
          : values.low;
        values.medium = this.Packlist.limitMedium
          ? this.Packlist.limitMedium
          : values.medium;
        values.high = this.Packlist.limitHigh
          ? this.Packlist.limitHigh
          : values.high;
        values.veryhigh = this.Packlist.limitVeryHigh
          ? this.Packlist.limitVeryHigh
          : values.veryhigh;
      }

      return values;
    },
    PercentLimits() {
      let values = {
        low: 20,
        medium: 50,
        high: 80,
        veryhigh: 100,
      };

      if (this.Packlist != null) {
        console.log(this.Packlist);
        values.low = this.Packlist.percentLow
          ? this.Packlist.percentLow
          : values.low;
        values.medium = this.Packlist.percentMedium
          ? this.Packlist.percentMedium
          : values.medium;
        values.high = this.Packlist.percentHigh
          ? this.Packlist.percentHigh
          : values.high;
        values.veryhigh = this.Packlist.percentVeryHigh
          ? this.Packlist.percentVeryHigh
          : values.veryhigh;
      }

      return values;
    },
    Liter() {
      let liter = 0;
      if (this.Packlist.packlistGear) {
        this.Packlist.packlistGear.forEach((element) => {
          let total = this.total(element.count, element.userGear.volume);
          liter += parseFloat(total);
        });
      }

      return liter;
    },
    AvailableLiter() {
      let liter = 0;
      if (this.Packlist.backpacks) {
        this.Packlist.backpacks.forEach((element) => {
          let total = this.total(element.count, element.userGear.volume);
          liter += parseFloat(total);
        });
      }

      return liter;
    },
    Gramm() {
      let weight = 0;
      if (this.Packlist.packlistGear) {
        this.Packlist.packlistGear.forEach((element) => {
          let total = this.total(element.count, element.userGear.gramm);
          weight += parseFloat(total);
        });
      }

      return weight;
    },
    isLoading() {
      return this.$store.state.packlists.selected.isLoading;
    },
  },
  methods: {
    async reload() {
      if (this.id) {
        await this.$store.dispatch("packlists/getSingle", this.id);
        await this.$store.dispatch("query/getGrammMetrics", this.id);
      }
    },
    NewShareCopy() {
      if (this.id) {
        this.$store
          .dispatch("packlists/createReadonlyCopy", this.id)
          .then((response) => {
            console.log(response);
            this.AccessCode = response.accessCode;

            this.$root.$emit("show_dialog", {
              title: "iFrame Code zum einbetten Ihrer Packliste",
              msg:
                '<iframe  width="100%" height="400px" scrolling="no" style="border: 0px; overflow: hidden;" src="' +
                process.env.VUE_APP_ROUTE +
                "/#share/" +
                this.AccessCode +
                '" >',
            });
          });
      }
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store
        .dispatch("packlists/removeItem", this.selectedItem)
        .then(() => {
          this.closeConfirmBar();
          this.$root.$emit("reload_packlist_data");
          //window.location.reload();
        });
    },
    editItem(item) {
      this.$router.push("/packlist/" + item.id);
    },
    countChange(item) {
      if (!item) return;

      item.count = parseInt(item.count);
      console.log(item);
      this.$store.dispatch("packlists/changeItemCount", item).then(() => {
        this.$root.$emit("reload_packlist_data");
        //window.location.reload();
      });
    },
    total(count, value) {
      let v = parseFloat(count) * parseFloat(value);
      return v;
    },
    limitLiterColor(value) {
      let percent = (parseFloat(value) / parseFloat(this.AvailableLiter)) * 100;
      this.percent = percent;

      if (percent < this.PercentLimits.low) {
        return "green";
      } else if (
        percent >= this.PercentLimits.low &&
        percent < this.PercentLimits.medium
      ) {
        return "yellow darken-2";
      } else if (
        percent >= this.PercentLimits.medium &&
        percent < this.PercentLimits.high
      ) {
        return "orange darken-2";
      } else if (
        percent >= this.PercentLimits.high &&
        percent < this.PercentLimits.veryhigh
      ) {
        return "red lighten-2";
      } else {
        return "red darken-4";
      }
    },
    limitColor(value) {
      if (value < this.Limits.low) {
        return "green";
      } else if (value >= this.Limits.low && value < this.Limits.medium) {
        return "yellow darken-2";
      } else if (value >= this.Limits.medium && value < this.Limits.high) {
        return "orange darken-2";
      } else if (value >= this.Limits.high && value < this.Limits.veryhigh) {
        return "red lighten-2";
      } else {
        return "red darken-4";
      }
    },
  },
  filters: {
    todecimal: function (value) {
      return Number.parseFloat(value).toFixed(2);
    },
  },
};
</script>
