var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Ihre geteilten Inhalte")]),_c('span',[_vm._v("Hier finden Sie sämtliche Packlisten welche Sie geteilt (oder in Ihrer Website eingebunden) haben.")]),_c('v-alert',{attrs:{"dense":"","border":"left","type":"warning"}},[_vm._v(" Achtung: Das löschen von geteilten Inhalten führt dazu, das der Inhalt nicht mehr für andere Zugreifbar ist. Sollten Sie einen der Inhalte auf Ihrer Website eingebunden haben wird er auch dort gelöscht. ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 color_table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.shares.items,"item-key":"id","loading":_vm.isLoading,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDate(item.createdAt)))])]}},{key:"item.x",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseAccessCode(item)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showConfirmBar(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true}])})],1)],1),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{attrs:{"bottom":"","timeout":10000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.closeConfirmBar()}}},'v-btn',attrs,false),[_vm._v(" Abbrechen ")]),_c('v-btn',_vm._b({attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.deleteItem()}}},'v-btn',attrs,false),[_vm._v(" Bestätigen ")])]}}]),model:{value:(_vm.confirmBar),callback:function ($$v) {_vm.confirmBar=$$v},expression:"confirmBar"}},[_vm._v(" Möchten Sie die Freigabe wirklich löschen? ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }