<template>
    <v-container fluid>
            <v-row>
                <v-col col="9">
                    <h3>Meine Packlisten</h3>
                    <span>Hier können Sie Ihre Ausrüstung verwalten sowie "Packlisten" erstellen und diese Packlisten dann bei Touren wiederverwenden und optimieren.</span>
                </v-col>
                 <v-col col="3">
                    <v-btn @click="SwitchToGrid(false)">Table</v-btn>
                    <v-btn @click="SwitchToGrid(true)">Grid</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" v-if="GridView">
                    <PacklistGridOverview></PacklistGridOverview>
                </v-col>
                <v-col col="12" v-else>
                    <PacklistTableOverview></PacklistTableOverview>
                </v-col>
            </v-row>
             <v-row>
                <v-col>
                    <NewPacklist></NewPacklist>
                </v-col>
            </v-row>
            
    </v-container>
</template>

<script>
import PacklistTableOverview from "./PacklistTableOverview.vue"
import PacklistGridOverview from "./PacklistGridOverview.vue"
import NewPacklist from "./NewPacklist.vue"

export default {    
    data() {
        return {
            GridView: false
        }
    },
    components: {
        PacklistTableOverview,
        PacklistGridOverview,
        NewPacklist
    },
    methods: {
        SwitchToGrid(showGrid) {
            this.GridView = showGrid;
        }
    }
}
</script>
