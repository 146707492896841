<template>
  <div>
    <v-container fluid style="margin: 0px; padding: 0px">
      <v-parallax
        src="https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
        height="380"
      ></v-parallax>
    </v-container>
    <v-container style="margin-top: -24em">
      <v-row>
        <v-col md="6" sm="12">
          <v-card style="padding: 1em">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  <v-icon>mdi-pulse</v-icon>Hallo {{ username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="6" sm="12">
          <NewestGearWidget></NewestGearWidget>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12" lg="4">
          <v-card style="padding: 1em">
            <v-card-title class="text-h5"> Deine Packlisten </v-card-title>

            <v-card-content>
              <span
                >Setze deine Planung fort. Hier sind deine neusten
                Packlisten</span
              >

              <PacklistTableOverview></PacklistTableOverview>
            </v-card-content>
          </v-card>
        </v-col>
        <v-col md="6" sm="12" lg="4">
          <v-card style="padding: 1em">
            <v-carousel
              :continuous="true"
              :show-arrows="true"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              height="360px;"
            >
              <v-carousel-item
                v-for="(slide, i) in recommendedProducts"
                :key="i"
              >
                  <span class="text-h5">
                    {{ slide.Titel }}
                  </span>


                    <v-row>
                      <v-col cols="6">
                        <v-img
                          :src="getAbsoluteImageUrl(slide.Bild.data.attributes.url)"
                          width="300"
                          height="auto"
                          @click="openUrl(slide.URL)"
                        ></v-img>
                      </v-col>
                      <v-col cols="6">
                       {{ slide.Text }}
                       
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <center>
                          <a :href="slide.URL" rel="sponsored" target="_blank" class="btn btn-primary">Mehr erfahren</a>
                        </center>
                      </v-col>
                    </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        
        <v-col md="6" sm="12" lg="4">
          <MostUsedGearWidget></MostUsedGearWidget>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mx-auto">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Optimierungsmöglichkeiten für Ihre Ausrüstung
                </v-list-item-title>
                <v-list-item-content>
                  Aktuell keine Optimierungsmöglichkeiten vorhanden
                </v-list-item-content>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col v-if="isLoading">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
                <v-col class="text-h2" cols="12" v-else> </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getUsername } from "../../helpers";

//import PacklistTableOverview from "./packlists/PacklistTableOverview.vue"
import MostUsedGearWidget from "../widgets/MostUsedGearWidget.vue";
import NewestGearWidget from "../widgets/NewestGearWidget.vue";
import PacklistTableOverview from "./packlists/PacklistTableOverview.vue";

export default {
  created() {
    this.getProductRecommendations();
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_packlist_data", () => {
      this.reload();
    });
  },
  components: {
    MostUsedGearWidget,
    NewestGearWidget,
    PacklistTableOverview,
  },
  data() {
    return {
      percent: 0,
      adProducts: [],
      AccessCode: "",
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Anzahl", value: "count" },
        { text: "Hersteller", value: "userGear.manufacturer.name" },
        { text: "Name", value: "userGear.name" },
        { text: "Kategorie", value: "userGear.gearCategory" },
        { text: "Gesamtgewicht", value: "totalGramm" },
        { text: "Gesamtvolumen", value: "totalVolume" },
        { text: "Packliste", value: "actions", sortable: false },
      ],
      search: "",

      tagChartGrammOptions: {
        chart: {
          title: "Aufteilung in Kategorien",
        },
        pieSliceText: "value",
      },
    };
  },

  computed: {
    recommendedProducts() {
      return this.adProducts;
    },
    isLoading() {
      return this.$store.state.packlists.all.isLoading;
    },
    isGearLoading() {
      return this.$store.state.usergear.all.loading;
    },
    username() {
      return getUsername();
    },
    
    gearLast() {
      return "";
    },
  },
  methods: {
    async reload() {
      if (this.id) {
        await this.$store.dispatch("packlists/getSingle", this.id);
        await this.$store.dispatch("packlists/getGrammMetrics", this.id);
      }
    },
    getAbsoluteImageUrl(image) {
      return process.env.VUE_APP_STRAPI_URL + image;
    },
    openUrl(url) {
      window.open(url);
    },
    async getProductRecommendations() {
      let baseUrl = process.env.VUE_APP_STRAPI_URL;
      let route = "/api/packlight-produktempfehlungens?populate=%2A";
      let requestUrl = baseUrl + route;

      const requestOptions = {
        method: "GET",
      };

      fetch(requestUrl, requestOptions).then((response) => {

        response.text().then((text) => {
          const data = text && JSON.parse(text);
          
          data.data.forEach((x) => {
              console.log(x);

              let empfehlung = x.attributes;



              this.adProducts.push(empfehlung);

          });
        });
      });
    },
  },
};
</script>
