var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-data-table',{staticClass:"elevation-1 color_table_secondary",attrs:{"dense":"","headers":_vm.headers,"items":_vm.usergear.items,"item-key":"id","loading":_vm.isLoading,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.volume",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLiter(item.volume)))])]}},{key:"item.mybuttons",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":function($event){return _vm.Save(item)}}},[_vm._v("Hinzufügen")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }